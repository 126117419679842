<template>
  <div>
    <h3>Mijn bedrijf</h3>

    <b-form @submit.prevent="adjustCustomer">
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-name"
            label="Bedrijfsnaam"
            label-for="input-customer-name"
          >
            <b-form-input
              id="input-customer-name"
              v-model="form.customerName"
              type="text"
              placeholder="Bedrijfsnaam"
              :disabled="isMainContact"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-website"
            label="Website"
            label-for="input-customer-website"
          >
            <b-form-input
              id="input-customer-website"
              v-model="form.website"
              type="text"
              :disabled="isMainContact"
              placeholder="Website"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-email"
            label="E-mailadres"
            label-for="input-customer-email"
          >
            <b-form-input
              id="input-customer-email"
              v-model="form.customerEmail"
              type="text"
              :disabled="isMainContact"
              placeholder="E-mailadres"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-street"
            label="Straat"
            label-for="input-street"
          >
            <b-form-input
              id="input-street"
              v-model="form.CustomerStreet"
              type="text"
              :disabled="isMainContact"
              placeholder="Straat"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumber"
            label="Huisnummer"
            label-for="input-housenumber"
          >
            <b-form-input
              id="input-housenumber"
              v-model="form.housenumber"
              type="text"
              :disabled="isMainContact"
              placeholder="Huisnummer"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumberaddition"
            label="Toevoeging"
            label-for="input-housenumberaddition"
          >
            <b-form-input
              id="input-housenumberaddition"
              v-model="form.housenumberaddition"
              type="text"
              :disabled="isMainContact"
              placeholder="Toevoeging"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-postal"
            label="Postcode"
            label-for="input-postal"
          >
            <b-form-input
              id="input-postal"
              v-model="form.zipCode"
              type="text"
              :disabled="isMainContact"
              placeholder="Postcode"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-city"
            label="Stad"
            label-for="input-city"
          >
            <b-form-input
              id="input-city"
              v-model="form.city"
              type="text"
              :disabled="isMainContact"
              placeholder="Stad"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <hr />
        <b-col cols="12">
          <h3>Gegevens van de hoofdverantwoordelijke</h3>
          <table class="table">
            <tr>
              <td>Voornaam</td>
              <td>{{ account.CustomerFirstName }}</td>
            </tr>
            <tr>
              <td>Achternaam</td>
              <td>{{ account.CustomerLastName || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ account.Email || 'Onbekend' }}</td>
            </tr>
            <tr>
              <td>Mobiel</td>
              <td>{{ account.CustomerTel || 'Onbekend' }}</td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12" v-if="false">
          <b-button
            v-if="!isMainContact"
            class="mb-2"
            type="submit"
            variant="primary"
            ><font-awesome-icon icon="save" /> Gegevens opslaan</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
const { updateCustomer } = require('@/services/AuthorizationService')
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BForm
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BForm
  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        customerEmail: '',
        customerName: '',
        customerDescription: '',
        street: '',
        housenumber: '',
        housenumberaddition: '',
        customerOrganisationNumber: '',
        zipCode: '',
        city: '',
        website: '',
      }
    }
  },
  computed: {
    account: function() {
      return this.$store.getters.account
    },
    customer() {
      return this.$store.state.customer
    },

    isMainContact: function() {
     return true
    }
  },
  created: function() {
    this.form.customerEmail = this.customer.Email
    this.form.CustomerStreet = this.customer.Street
    this.form.zipCode = this.customer.ZipCode
    this.form.housenumber = this.customer.HouseNumber
    this.form.housenumberaddition = this.customer.HouseNumberAddition
    this.form.city = this.customer.City
    this.form.customerName = this.customer.CompanyName
    this.form.website = this.customer.Website
  },
  methods: {
    adjustCustomer: async function() {
      const result = await updateCustomer({
        CustomerEmail: this.form.customerEmail,
        CustomerStreet: this.form.CustomerStreet,
        ZipCode: this.form.zipCode,
        HouseNumber: this.form.housenumber,
        HouseNumberAddition: this.form.housenumberaddition,
        Website: this.form.website,
        City: this.form.city,
        CompanyName: this.form.customerName,
        CustomerDescription: this.account.CustomerCUST_Notes,
        ECCUST_EnterpriseNumber: this.form.customerOrganisationNumber,
        CUST_SocialMedia: this.form.CUST_SocialMedia
      })
      if (result) {
        this.notify({ message: 'Gegevens succesvol bijgewerkt!' })
      }
    },
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    }
  }
}
</script>
